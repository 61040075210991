import {PriceEntry} from './PriceEntry';

export class FilterResultSummary implements IFilterResultSummary {
    private _avgHighTime: string;
    private _avgLowTime: string;
    private _data: PriceEntry[];
    private _duration: number;
    private _finished: boolean;
    private _greenRatio: number;

    private _avgPerformance: number;
    private _avgPerformanceGreen: number;
    private _avgPerformanceRed: number;
    private _performanceHighClose: number;
    private _performanceHighLow: number;
    private _performanceLowClose: number;
    private _performanceLowHigh: number;
    private _performanceOpenHigh: number;
    private _performanceOpenLow: number;
    private _performancePmHighHigh: number;

    private _medianPerformance: number;
    private _medianPerformanceOpenHigh: number;
    private _medianPerformanceOpenLow: number;
    private _medianPerformanceHighClose: number;
    private _medianPerformanceLowClose: number;
    private _medianPerformanceLowHigh: number;
    private _medianPerformanceHighLow: number;
    private _medianPerformanceGreen: number;
    private _medianPerformanceRed: number;
    private _medianPerformancePmHighHigh: number;

    private _pmHighBrakeRatio: number;
    private _processed: number;
    private _redRatio: number;
    private _resultCount: number;
    private _tickersCount: number;
    private _nextDaySummary: FilterResultSummary | null;

    get avgHighTime(): string {
        return this._avgHighTime;
    }

    get avgLowTime(): string {
        return this._avgLowTime;
    }

    get avgPerformance(): number {
        return this._avgPerformance;
    }

    get avgPerformanceGreen(): number {
        return this._avgPerformanceGreen;
    }

    get avgPerformanceRed(): number {
        return this._avgPerformanceRed;
    }

    get data(): PriceEntry[] {
        return this._data;
    }

    get duration(): number {
        return this._duration;
    }

    get finished(): boolean {
        return this._finished;
    }

    get greenRatio(): number {
        return this._greenRatio;
    }

    get performanceHighClose(): number {
        return this._performanceHighClose;
    }

    get performanceHighLow(): number {
        return this._performanceHighLow;
    }

    get performanceLowClose(): number {
        return this._performanceLowClose;
    }

    get performanceLowHigh(): number {
        return this._performanceLowHigh;
    }

    get performancePmHighHigh(): number {
        return this._performancePmHighHigh;
    }

    get performanceOpenHigh(): number {
        return this._performanceOpenHigh;
    }

    get performanceOpenLow(): number {
        return this._performanceOpenLow;
    }

    get processed(): number {
        return this._processed;
    }

    get redRatio(): number {
        return this._redRatio;
    }

    get resultCount(): number {
        return this._resultCount;
    }

    get tickersCount(): number {
        return this._tickersCount;
    }

    get pmHighBrakeRatio(): number {
        return this._pmHighBrakeRatio;
    }

    get nextDaySummary(): FilterResultSummary | null {
        return this._nextDaySummary;
    }

    get medianPerformance(): number {
        return this._medianPerformance;
    }

    get medianPerformanceOpenHigh(): number {
        return this._medianPerformanceOpenHigh;
    }

    get medianPerformanceOpenLow(): number {
        return this._medianPerformanceOpenLow;
    }

    get medianPerformanceHighClose(): number {
        return this._medianPerformanceHighClose;
    }

    get medianPerformanceLowClose(): number {
        return this._medianPerformanceLowClose;
    }

    get medianPerformanceLowHigh(): number {
        return this._medianPerformanceLowHigh;
    }

    get medianPerformanceHighLow(): number {
        return this._medianPerformanceHighLow;
    }

    get medianPerformanceGreen(): number {
        return this._medianPerformanceGreen;
    }

    get medianPerformanceRed(): number {
        return this._medianPerformanceRed;
    }

    get medianPerformancePmHighHigh(): number {
        return this._medianPerformancePmHighHigh;
    }

    constructor(avgHighTime: string, avgLowTime: string, data: PriceEntry[], duration: number, finished: boolean, greenRatio: number, avgPerformance: number, avgPerformanceGreen: number, avgPerformanceRed: number, performanceHighClose: number, performanceHighLow: number, performanceLowClose: number, performanceLowHigh: number, performanceOpenHigh: number, performanceOpenLow: number, performancePmHighHigh: number, medianPerformance: number, medianPerformanceOpenHigh: number, medianPerformanceOpenLow: number, medianPerformanceHighClose: number, medianPerformanceLowClose: number, medianPerformanceLowHigh: number, medianPerformanceHighLow: number, medianPerformanceGreen: number, medianPerformanceRed: number, medianPerformancePmHighHigh: number, pmHighBrakeRatio: number, processed: number, redRatio: number, resultCount: number, tickersCount: number, nextDaySummary: FilterResultSummary | null) {
        this._avgHighTime = avgHighTime;
        this._avgLowTime = avgLowTime;
        this._data = data;
        this._duration = duration;
        this._finished = finished;
        this._greenRatio = greenRatio;
        this._avgPerformance = avgPerformance;
        this._avgPerformanceGreen = avgPerformanceGreen;
        this._avgPerformanceRed = avgPerformanceRed;
        this._performanceHighClose = performanceHighClose;
        this._performanceHighLow = performanceHighLow;
        this._performanceLowClose = performanceLowClose;
        this._performanceLowHigh = performanceLowHigh;
        this._performanceOpenHigh = performanceOpenHigh;
        this._performanceOpenLow = performanceOpenLow;
        this._performancePmHighHigh = performancePmHighHigh;
        this._medianPerformance = medianPerformance;
        this._medianPerformanceOpenHigh = medianPerformanceOpenHigh;
        this._medianPerformanceOpenLow = medianPerformanceOpenLow;
        this._medianPerformanceHighClose = medianPerformanceHighClose;
        this._medianPerformanceLowClose = medianPerformanceLowClose;
        this._medianPerformanceLowHigh = medianPerformanceLowHigh;
        this._medianPerformanceHighLow = medianPerformanceHighLow;
        this._medianPerformanceGreen = medianPerformanceGreen;
        this._medianPerformanceRed = medianPerformanceRed;
        this._medianPerformancePmHighHigh = medianPerformancePmHighHigh;
        this._pmHighBrakeRatio = pmHighBrakeRatio;
        this._processed = processed;
        this._redRatio = redRatio;
        this._resultCount = resultCount;
        this._tickersCount = tickersCount;
        this._nextDaySummary = nextDaySummary;
    }

    static empty(): FilterResultSummary {
        return new FilterResultSummary(
            '', '', [], 0, true, 0, 0, 0,
            0, 0, 0, 0, 0, 0,
            0, 0, 0,
            0, 0, 0, 0,
            0, 0, 0, 0, 0,
            0, 0, 0, 0 ,0, null
        )
    }
}

interface IFilterResultSummary {
    avgPerformance: number;
    avgPerformanceRed: number;
    avgPerformanceGreen: number;
    avgHighTime: string;
    avgLowTime: string;
    greenRatio: number;
    redRatio: number;
    processed: number;
    tickersCount: number;
    duration: number;
    data: PriceEntry[];
    finished: boolean;
    resultCount: number;

    performanceOpenHigh: number;
    performanceOpenLow: number;
    performanceHighClose: number;
    performanceLowClose: number;
    performanceLowHigh: number;
    performanceHighLow: number;
    performancePmHighHigh: number;
    pmHighBrakeRatio: number;

    medianPerformance: number;
    medianPerformanceOpenHigh: number;
    medianPerformanceOpenLow: number;
    medianPerformanceHighClose: number;
    medianPerformanceLowClose: number;
    medianPerformanceLowHigh: number;
    medianPerformanceHighLow: number;
    medianPerformanceGreen: number;
    medianPerformanceRed: number;
    medianPerformancePmHighHigh: number;
}
