import React from 'react';
import { Box, Skeleton, Tooltip, Typography } from '@mui/material';

import { FilterResultSummary } from '../../types/FilterResultSummary';
import { gap, percentage } from '../../common/utils/utils';

interface Props {
    priceData: FilterResultSummary | null;
    hideSearchInfo?: boolean;
    showAvg: boolean;
}

const LoadingSkeleton = () => {
    return (
        <Box display="grid" gridTemplateColumns="repeat(5, 1fr)" minHeight={383} padding="24px" justifyItems="center" >
            {[...new Array(15)].map((_, idx) => (
                <Box key={idx}>
                    <Skeleton variant="rounded" animation="wave" width={112} height={71} />
                </Box>
            ))}
        </Box>
    );
};

export default function TableSummaryNewLayout({ priceData, hideSearchInfo, showAvg }: Props) {
    if (!priceData) return <LoadingSkeleton />;

    return (
        <Box display="grid" gridTemplateColumns="repeat(5, 1fr)" minHeight={383} padding="24px"
             justifyItems="center"
             alignItems="center"
             sx={{textAlign: 'center'}}>
            <Box maxWidth={112} width="100%">
                <Tooltip title="Average performance of the setup in the given time frame">
                    <Typography fontSize="14px" whiteSpace="pre">
                        {showAvg ? 'Avg\nCHG %' : 'Median\nCHG'}
                    </Typography>
                </Tooltip>
                <Typography marginTop="5px">
                    {gap(showAvg ? priceData.avgPerformance : priceData.medianPerformance)}
                </Typography>
            </Box>
            <Box maxWidth={112} width="100%">
                <Tooltip title="Value shows on how many occasions the stock closed green in the the given time frame">
                    <Typography fontSize="14px" whiteSpace="pre">
                        {'Closed\ngreen %'}
                    </Typography>
                </Tooltip>
                <Typography marginTop="5px">{gap(priceData.greenRatio)}</Typography>
            </Box>
            <Box maxWidth={112} width="100%">
                <Tooltip title="Value shows on how many occasions the stock closed red in the given time frame">
                    <Typography fontSize="14px" whiteSpace="pre">
                        {'Closed\nred %'}
                    </Typography>
                </Tooltip>
                <Typography marginTop="5px">
                    {gap(priceData.redRatio ? -priceData.redRatio : priceData.redRatio)}
                </Typography>
            </Box>
            <Box maxWidth={112} width="100%">
                <Tooltip title="Value shows what was the average performance of the stock when it closed green in the given time frame">
                    <Typography fontSize="14px" whiteSpace="pre">
                        {showAvg ? 'Avg green\nCHG %' : 'Median green\nCHG'}
                    </Typography>
                </Tooltip>
                <Typography marginTop="5px">
                    {gap(
                        showAvg ? priceData.avgPerformanceGreen : priceData.medianPerformanceGreen
                    )}
                </Typography>
            </Box>
            <Box maxWidth={112} width="100%">
                <Tooltip title="Value shows what was the average performance of the stock when it closed red in the given time frame">
                    <Typography fontSize="14px" whiteSpace="pre">
                        {showAvg ? 'Avg red\nCHG %' : 'Median red\nCHG'}
                    </Typography>
                </Tooltip>
                <Typography marginTop="5px">
                    {gap(showAvg ? priceData.avgPerformanceRed : priceData.medianPerformanceRed)}
                </Typography>
            </Box>
            <Box maxWidth={112} width="100%">
                <Typography fontSize="14px" whiteSpace="pre">
                    {showAvg ? 'Avg CHG\n(Open - HOD)' : 'Median CHG\n(Open - HOD)'}
                </Typography>
                <Typography marginTop="5px">
                    {gap(
                        showAvg
                            ? priceData.performanceOpenHigh
                            : priceData.medianPerformanceOpenHigh
                    )}
                </Typography>
            </Box>
            <Box maxWidth={112} width="100%">
                <Typography fontSize="14px" whiteSpace="pre">
                    {showAvg ? 'Avg CHG\n(Open - LOD)' : 'Median CHG\n(Open - LOD)'}
                </Typography>
                <Typography marginTop="5px">
                    {gap(
                        showAvg ? priceData.performanceOpenLow : priceData.medianPerformanceOpenLow
                    )}
                </Typography>
            </Box>
            <Box maxWidth={112} width="100%">
                <Typography fontSize="14px" whiteSpace="pre">
                    {showAvg ? 'Avg CHG\n(HOD - Close)' : 'Median CHG\n(HOD - Close)'}
                </Typography>
                <Typography marginTop="5px">
                    {gap(
                        showAvg
                            ? priceData.performanceHighClose
                            : priceData.medianPerformanceHighClose
                    )}
                </Typography>
            </Box>
            <Box maxWidth={112} width="100%">
                <Typography fontSize="14px" whiteSpace="pre">
                    {showAvg ? 'Avg CHG\n(LOD - Close)' : 'Median CHG\n(LOD - Close)'}
                </Typography>
                <Typography marginTop="5px">
                    {gap(
                        showAvg
                            ? priceData.performanceLowClose
                            : priceData.medianPerformanceLowClose
                    )}
                </Typography>
            </Box>
            <Box maxWidth={112} width="100%">
                <Typography fontSize="14px" whiteSpace="pre">
                    {showAvg ? 'Avg CHG\n(LOD - HOD)' : 'Median CHG\n(LOD - HOD)'}
                </Typography>
                <Typography marginTop="5px">
                    {gap(
                        showAvg ? priceData.performanceLowHigh : priceData.medianPerformanceLowHigh
                    )}
                </Typography>
            </Box>
            <Box maxWidth={112} width="100%">
                <Typography fontSize="14px" whiteSpace="pre">
                    {showAvg ? 'Avg CHG\n(HOD - LOD)' : 'Median CHG\n(HOD - LOD)'}
                </Typography>
                <Typography marginTop="5px">
                    {gap(
                        showAvg ? priceData.performanceHighLow : priceData.medianPerformanceHighLow
                    )}
                </Typography>
            </Box>
            <Box maxWidth={112} width="100%">
                <Typography fontSize="14px" whiteSpace="pre">
                    {showAvg ? 'Avg CHG\n(PM High - HOD)' : 'Median CHG\n(PM High - HOD)'}
                </Typography>
                <Typography marginTop="5px">
                    {gap(
                        showAvg
                            ? priceData.performancePmHighHigh
                            : priceData.medianPerformancePmHighHigh
                    )}
                </Typography>
            </Box>
            <Box maxWidth={112} width="100%">
                <Typography fontSize="14px" whiteSpace="pre">
                    {'PM High\nbreak %'}
                </Typography>
                <Typography marginTop="5px">{percentage(priceData.pmHighBrakeRatio)}</Typography>
            </Box>
            <Box maxWidth={112} width="100%">
                <Typography fontSize="14px" whiteSpace="pre">
                    {'Avg HOD\ntime'}
                </Typography>
                <Typography marginTop="5px">{priceData.avgHighTime}</Typography>
            </Box>
            <Box maxWidth={112} width="100%">
                <Typography fontSize="14px" whiteSpace="pre">
                    {'Avg LOD\ntime'}
                </Typography>
                <Typography marginTop="5px">{priceData.avgLowTime}</Typography>
            </Box>
            {hideSearchInfo ? null : (
                <>
                    <Box maxWidth={112} width="100%">
                        <Typography fontSize="14px" whiteSpace="pre">
                            {'Result\ncount'}
                        </Typography>
                        <Typography marginTop="5px">
                            {priceData ? priceData.resultCount : 0}
                        </Typography>
                    </Box>
                    <Box maxWidth={112} width="100%">
                        <Typography fontSize="14px" whiteSpace="pre">
                            {'Tickers\nprocessed'}
                        </Typography>
                        <Typography marginTop="5px">
                            {priceData ? priceData.processed + '/' + priceData.tickersCount : 0}
                        </Typography>
                    </Box>
                    <Box maxWidth={112} width="100%">
                        <Typography fontSize="14px" whiteSpace="pre">
                            Duration
                        </Typography>
                        <Typography marginTop="5px">
                            {priceData ? priceData.duration + 's' : 0}{' '}
                        </Typography>
                    </Box>
                </>
            )}
        </Box>
    );
}
