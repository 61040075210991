import {Grid} from '@mui/material';
import TypedFilterInput from './TypedFilterInput';
import React from 'react';
import {FilterArray} from './FilterArray';
import {noOp} from '../../common/utils/utils';

interface IntraRangeFiltersProps {
    intraRanges: FilterArray[];
    isNewLayout: boolean;
}

export default function IntraRangeFilters({intraRanges, isNewLayout} : IntraRangeFiltersProps) {
    return (<>
        {intraRanges.map((array, i) => {
            return (<>
                {array.filters?.map((filter, j) => {
                    return (
                        <Grid
                            key={j}
                            item
                            xs={isNewLayout ? 12 : 12}
                            sm={isNewLayout ? 12 : 6}
                            md={isNewLayout ? 12 : 4}
                            lg={isNewLayout ? 6 : 3}
                            xl={isNewLayout ? 6 : 2}
                        >
                            <TypedFilterInput
                                filter={filter}
                                checkActiveCount={noOp}
                            />
                        </Grid>
                    );
                })}
            </>)
        })}
    </>)
}
