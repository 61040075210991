import React from 'react';
import { TableCell, TableSortLabel, Tooltip, Typography } from '@mui/material';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Order } from '../../types/Order';
import { PriceEntry } from '../../types/PriceEntry';
import {colors} from '../../common/theme';

const classes = {
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
    },
};
type SortableTableHeadCellProps = {
    orderBy: string;
    cell: any;
    createSortHandler: (property: keyof PriceEntry) => void;
    order: Order;
};

const SortableTableHeadCell = ({
    orderBy,
    cell,
    createSortHandler,
    order,
}: SortableTableHeadCellProps) => {
    const { attributes, listeners, setNodeRef, transform, transition,isDragging } = useSortable({
        id: cell.id,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        backgroundColor: isDragging ? colors.gray.dim : '',
    };

    return (
        <TableCell
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            sortDirection={orderBy === cell.id ? order : false}
            align="left"
            sx={{ whiteSpace: 'pre', cursor: 'col-resize' }}
        >
            <Tooltip title={cell.tooltip ? cell.tooltip : ''}>
                {!cell.noSort ? (
                    <TableSortLabel
                        id={cell.id}
                        active={orderBy === cell.id}
                        direction={order}
                        onClick={()=>createSortHandler(cell.id)}
                    >
                        {cell.label}
                        {orderBy === cell.id ? (
                            <Typography component="span" sx={classes.visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Typography>
                        ) : null}
                    </TableSortLabel>
                ) : (
                    <span >{cell.label}</span>
                )}
            </Tooltip>
        </TableCell>
    );
};

export default SortableTableHeadCell;
