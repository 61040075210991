import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import {ApiResponse} from '../../types/ApiResponse';
import {FilterResultSummary} from '../../types/FilterResultSummary';
import {formatQueryDate, formatQueryTime, joinArray, noOp} from '../utils/utils';
import apiService from '../utils/apiService';
import {useQueryDate, useQueryString, useQueryTime} from '../utils/useQueryString';
import moment from 'moment';
import {sendBody} from '../utils/sendBody';
import {Order} from '../../types/Order';
import {FilterContextType, FilterType} from './FilterContextTypes';
import {FilterArray} from '../../component/filter/FilterArray';
import {bodyToDto} from './dtoUtil';

export const FilterContext = createContext<FilterContextType>({
    response: ApiResponse.empty(),
    dataResponse: ApiResponse.empty(),
    filters: emptyFilters(),
    getQuery: () => '',
    search: noOp(),
    fetch: noOp(),
    finish: noOp(),
    clear: noOp(),
    pageAndRows: {
        page: null,
        rowsPerPage: null,
        set: noOp()
    },
    ordering: {
        order: 'desc',
        orderBy: 'last',
        set: noOp()
    },
    isNewLayout: true,
    setIsNewLayout: noOp()
});

interface ProviderProps {
    children: ReactNode;
}

export function FilterContextProvider({ children }: ProviderProps) {
    const [searching, setSearching] = useState<boolean>(false);
    const [searchingData, setSearchingData] = useState<boolean>(false);
    const selectedLayout = localStorage.getItem('newLayout');
    const [isNewLayout, setIsNewLayout] = useState<boolean>(selectedLayout === null || selectedLayout === 'true');
    const [dateFrom, setDateFrom] = useQueryDate('from', moment().subtract(7, 'days'));
    const [dateTo, setDateTo] = useQueryDate('to', moment());
    const [insiderOwnership, setInsiderOwnership] = useQueryString('own', [null, null]);
    const [priceRange, setPriceRange] = useQueryString('price', [null, null]);
    const [rvol, setRvol] = useQueryString('rvol', [null, null]);
    const [pmRvol, setPmRvol] = useQueryString('pmRvol', [null, null]);
    const [avgVol, setAvgVol] = useQueryString('avgVol', [null, null]);
    const [avgPmVol, setPmAvgVol] = useQueryString('avgPmVol', [null, null]);
    const [performance, setPerformance] = useQueryString('performance', [null, null]);
    const [performanceOpenHigh, setPerformanceOpenHigh] = useQueryString('performanceOpenHigh', [null, null]);
    const [performanceOpenLow, setPerformanceOpenLow] = useQueryString('performanceOpenLow', [null, null]);
    const [performanceHighClose, setPerformanceHighClose] = useQueryString('performanceHighClose', [null, null]);
    const [performanceLowClose, setPerformanceLowClose] = useQueryString('performanceLowClose', [null, null]);
    const [performanceLowHigh, setPerformanceLowHigh] = useQueryString('performanceLowHigh', [null, null]);
    const [performanceHighLow, setPerformanceHighLow] = useQueryString('performanceHighLow', [null, null]);
    const [performancePmHighHigh, setPerformancePmHighHigh] = useQueryString('performancePmHighHigh', [null, null]);
    const [pmGap, setPmGap] = useQueryString('pmGap', [null, null]);
    const [pmHighGap, setPmHighGap] = useQueryString('pmHighGap', [null, null]);
    const [volumeYesterday, setVolumeYesterday] = useQueryString('volumeYesterday', [null, null]);
    const [changePreviousDay, setChangePreviousDay] = useQueryString('changePreviousDay', [null, null]);
    const [changeFiveDays, setChangeFiveDays] = useQueryString('changeFiveDays', [null, null]);
    const [changeTenDays, setChangeTenDays] = useQueryString('changeTenDays', [null, null]);
    const [changeTwentyDays, setChangeTwentyDays] = useQueryString('changeTwentyDays', [null, null]);
    const [dailyRange, setDailyRange] = useQueryString('dailyRange', [null, null]);
    const [dailyRangeAvg, setDailyRangeAvg] = useQueryString('dailyRangeAvg', [null, null]);
    const [trueRange, setTrueRange] = useQueryString('trueRange', [null, null]);
    const [trueRangeAvg, setTrueRangeAvg] = useQueryString('trueRangeAvg', [null, null]);
    const [gap, setGap] = useQueryString('gap', [null, null]);
    const [marketCap, setMarketCap] = useQueryString('mc', [null, null]);
    const [shares, setShares] = useQueryString('shares', [null, null]);
    const [volume, setVolume] = useQueryString('vol', [null, null]);
    const [pmVolume, setPmVolume] = useQueryString('pmVol', [null, null]);
    const [pmFade, setPmFade] = useQueryString('pmFade', [null, null]);
    const [pmBreak, setPmBreak] = useQueryString('pmBreak', undefined);
    const [tickers, setTickers] = useQueryString('tickers', []);
    const [sectors, setSectors] = useQueryString('sectors', []);
    const [countries, setCountries] = useQueryString('countries', []);
    const [industries, setIndustries] = useQueryString('industries', []);
    const [dollarVolume, setDollarVolume] = useQueryString('dollarVolume', [null, null]);
    const [hodFrom, setHodFrom] = useQueryTime('hodFrom', null);
    const [hodTo, setHodTo] = useQueryTime('hodTo', null);
    const [lodFrom, setLodFrom] = useQueryTime('lodFrom', null);
    const [lodTo, setLodTo] = useQueryTime('lodTo', null);
    const [pmHighFrom, setPmHighFrom] = useQueryTime('pmHighFrom', null);
    const [pmHighTo, setPmHighTo] = useQueryTime('pmHighTo', null);
    const [pmLowFrom, setPmLowFrom] = useQueryTime('pmLowFrom', null);
    const [pmLowTo, setPmLowTo] = useQueryTime('pmLowTo', null);
    const [outputFrom, setOutputFrom] = useQueryTime('outFrom', moment('0930', 'HHmm'));
    const [outputTo, setOutputTo] = useQueryTime('outTo', moment('1559', 'HHmm'));

    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(100);

    const [order, setOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState<string>('last');

    function getQuery() : string {
        return (dateFrom ? '?from=' + formatQueryDate(dateFrom) : '')
            + (dateTo ? '&to=' + formatQueryDate(dateTo) : '')
            + (hodFrom ? '&hodFrom=' + formatQueryTime(hodFrom) : '')
            + (hodTo ? '&hodTo=' + formatQueryTime(hodTo) : '')
            + (lodFrom ? '&lodFrom=' + formatQueryTime(lodFrom) : '')
            + (lodTo ? '&lodTo=' + formatQueryTime(lodTo) : '')
            + (pmHighFrom ? '&pmHighFrom=' + formatQueryTime(pmHighFrom) : '')
            + (pmHighTo ? '&pmHighTo=' + formatQueryTime(pmHighTo) : '')
            + (pmLowFrom ? '&pmLowFrom=' + formatQueryTime(pmLowFrom) : '')
            + (pmLowTo ? '&pmLowTo=' + formatQueryTime(pmLowTo) : '')
            + (outputFrom ? '&outputFrom=' + formatQueryTime(outputFrom) : '')
            + (outputTo ? '&outputTo=' + formatQueryTime(outputTo) : '')
            + (priceRange[0] ? '&pf=' + priceRange[0] : '')
            + (priceRange[1] ? '&pt=' + priceRange[1] : '')
            + (marketCap[0] ? '&mcf=' + (marketCap[0]) : '')
            + (marketCap[1] ? '&mct=' + (marketCap[1]) : '')
            + (shares[0] ? '&sf=' + (shares[0]) : '')
            + (shares[1] ? '&st=' + (shares[1]) : '')
            + (volume[0] ? '&vf=' + (volume[0]) : '')
            + (volume[1] ? '&vt=' + (volume[1]) : '')
            + (pmVolume[0] ? '&pmvf=' + (pmVolume[0]) : '')
            + (pmVolume[1] ? '&pmvt=' + (pmVolume[1]) : '')
            + (pmFade[0] ? '&pmFadeFrom=' + pmFade[0] : '')
            + (pmFade[1] ? '&pmFadeTo=' + pmFade[1] : '')
            + (pmBreak ? '&pmBreak=' + pmBreak : '')
            + (insiderOwnership[0] ? '&of=' + insiderOwnership[0] : '')
            + (insiderOwnership[1] ? '&ot=' + insiderOwnership[1] : '')
            + (performance[0] ? '&performanceFrom=' + performance[0] : '')
            + (performance[1] ? '&performanceTo=' + performance[1] : '')
            + (performanceOpenHigh[0] ? '&performanceOpenHighFrom=' + performanceOpenHigh[0] : '')
            + (performanceOpenHigh[1] ? '&performanceOpenHighTo=' + performanceOpenHigh[1] : '')
            + (performanceOpenLow[0] ? '&performanceOpenLowFrom=' + performanceOpenLow[0] : '')
            + (performanceOpenLow[1] ? '&performanceOpenLowTo=' + performanceOpenLow[1] : '')
            + (performanceHighClose[0] ? '&performanceHighCloseFrom=' + performanceHighClose[0] : '')
            + (performanceHighClose[1] ? '&performanceHighCloseTo=' + performanceHighClose[1] : '')
            + (performanceLowClose[0] ? '&performanceLowCloseFrom=' + performanceLowClose[0] : '')
            + (performanceLowClose[1] ? '&performanceLowCloseTo=' + performanceLowClose[1] : '')
            + (performanceLowHigh[0] ? '&performanceLowHighFrom=' + performanceLowHigh[0] : '')
            + (performanceLowHigh[1] ? '&performanceLowHighTo=' + performanceLowHigh[1] : '')
            + (performanceHighLow[0] ? '&performanceHighLowFrom=' + performanceHighLow[0] : '')
            + (performanceHighLow[1] ? '&performanceHighLowTo=' + performanceHighLow[1] : '')
            + (performancePmHighHigh[0] ? '&performancePmHighHighFrom=' + performancePmHighHigh[0] : '')
            + (performancePmHighHigh[1] ? '&performancePmHighHighTo=' + performancePmHighHigh[1] : '')
            + (pmGap[0] ? '&pmGapFrom=' + pmGap[0] : '')
            + (pmGap[1] ? '&pmGapTo=' + pmGap[1] : '')
            + (pmHighGap[0] ? '&pmHighGapFrom=' + pmHighGap[0] : '')
            + (pmHighGap[1] ? '&pmHighGapTo=' + pmHighGap[1] : '')
            + (volumeYesterday[0] ? '&volumeYesterdayFrom=' + volumeYesterday[0] : '')
            + (volumeYesterday[1] ? '&volumeYesterdayTo=' + volumeYesterday[1] : '')
            + (changePreviousDay[0] ? '&changePreviousDayFrom=' + changePreviousDay[0] : '')
            + (changePreviousDay[1] ? '&changePreviousDayTo=' + changePreviousDay[1] : '')
            + (changeFiveDays[0] ? '&changeFiveDaysFrom=' + changeFiveDays[0] : '')
            + (changeFiveDays[1] ? '&changeFiveDaysTo=' + changeFiveDays[1] : '')
            + (changeTenDays[0] ? '&changeTenDaysFrom=' + changeTenDays[0] : '')
            + (changeTenDays[1] ? '&changeTenDaysTo=' + changeTenDays[1] : '')
            + (changeTwentyDays[0] ? '&changeTwentyDaysFrom=' + changeTwentyDays[0] : '')
            + (changeTwentyDays[1] ? '&changeTwentyDaysTo=' + changeTwentyDays[1] : '')
            + (dailyRange[0] ? '&dailyRangeFrom=' + dailyRange[0] : '')
            + (dailyRange[1] ? '&dailyRangeTo=' + dailyRange[1] : '')
            + (dailyRangeAvg[0] ? '&dailyRangeAvgFrom=' + dailyRangeAvg[0] : '')
            + (dailyRangeAvg[1] ? '&dailyRangeAvgTo=' + dailyRangeAvg[1] : '')
            + (trueRange[0] ? '&trueRangeFrom=' + trueRange[0] : '')
            + (trueRange[1] ? '&trueRangeTo=' + trueRange[1] : '')
            + (trueRangeAvg[0] ? '&trueRangeAvgFrom=' + trueRangeAvg[0] : '')
            + (trueRangeAvg[1] ? '&trueRangeAvgTo=' + trueRangeAvg[1] : '')
            + (gap[0] ? '&gf=' + gap[0] : '')
            + (gap[1] ? '&gt=' + gap[1] : '')
            + (rvol[0] ? '&rvolF=' + rvol[0] : '')
            + (rvol[1] ? '&rvolT=' + rvol[1] : '')
            + (pmRvol[0] ? '&pmrvolf=' + pmRvol[0] : '')
            + (pmRvol[1] ? '&pmrvolt=' + pmRvol[1] : '')
            + (avgVol[0] ? '&avf=' + avgVol[0] : '')
            + (avgVol[1] ? '&avt=' + avgVol[1] : '')
            + (avgPmVol[0] ? '&apvf=' + avgPmVol[0] : '')
            + (avgPmVol[1] ? '&apvt=' + avgPmVol[1] : '')
            + (tickers ? '&tickers=' + joinArray(tickers) : '')
            + (sectors ? '&sectors=' + joinArray(sectors) : '')
            + (industries ? '&industries=' + joinArray(industries) : '')
            + (countries ? '&countries=' + joinArray(countries) : '')
            + (dollarVolume[0] ? '&dollarVolumeFrom=' + dollarVolume[0] : '')
            + (dollarVolume[1] ? '&dollarVolumeTo=' + dollarVolume[1] : '')
            + '&type=DAILY';
    }

    const search = (body?: FilterArray[]): void => {
        let r = '';
        if (response.result && response.result.finished) {
            r = '&r=true';
        }
        const q = getQuery();
        setQuery(q);

        function search() {
            dataResponse.invalidateResult();
            response.fetchData('filter/summary' + pagedQuery(q) + r);
            setPage(0);
        }

        if (body) {
            sendBody(
                bodyToDto(body),
                '/api/filter/multi-init'  + getQuery(),
                'POST',
                () => search());
        } else {
            search();
        }
    };

    const fetch = (): void => {
        const q = getQuery();
        setQuery(q);
        dataResponse.invalidateResult();
        response.fetchData('filter/summary' + pagedQuery(q));
    }

    const finish = (): void => {
        sendBody(
            {},
            '/api/filter/finish'  + query,
            'PUT'
        );
    };

    const clear = (): void => {
        setDateFrom(moment().subtract(7, 'days'));
        setDateTo(moment());
        setInsiderOwnership([null, null]);
        setPriceRange([null, null]);
        setRvol([null, null]);
        setPmRvol([null, null]);
        setAvgVol([null, null]);
        setPmAvgVol([null, null]);
        setPerformance([null, null]);
        setPerformanceOpenHigh([null, null]);
        setPerformanceOpenLow([null, null]);
        setPerformanceHighClose([null, null]);
        setPerformanceLowClose([null, null]);
        setPerformanceLowHigh([null, null]);
        setPerformanceHighLow([null, null]);
        setPerformancePmHighHigh([null, null]);
        setPmGap([null, null]);
        setPmHighGap([null, null]);
        setVolumeYesterday([null, null]);
        setChangePreviousDay([null, null]);
        setChangeFiveDays([null, null]);
        setChangeTenDays([null, null]);
        setChangeTwentyDays([null, null]);
        setDailyRange([null, null]);
        setDailyRangeAvg([null, null]);
        setTrueRange([null, null]);
        setTrueRangeAvg([null, null]);
        setGap([null, null]);
        setMarketCap([null, null]);
        setShares([null, null]);
        setVolume([null, null]);
        setPmVolume([null, null]);
        setPmFade([null, null]);
        setPmBreak(undefined);
        setTickers([]);
        setSectors([]);
        setCountries([]);
        setIndustries([]);
        setDollarVolume([null, null]);
        setHodFrom(null);
        setHodTo(null);
        setLodFrom(null);
        setLodTo(null);
        setPmHighFrom(null);
        setPmHighTo(null);
        setPmLowFrom(null);
        setPmLowTo(null);
        setOutputFrom(moment('0930', 'HHmm'));
        setOutputTo(moment('1559', 'HHmm'));

        setOrder('desc');
        setOrderBy('last');
    }

    const [query, setQuery] = useState(getQuery());
    const pagedQuery = (q?: string, p?: number, r?: number, o?: string, ob?: string) => {
        return (q ? q : query)
            + '&page=' + (p !== undefined ? p : page)
            + '&count=' + (r ? r : rowsPerPage)
            + '&order=' + (o ? o : order)
            + '&orderBy=' + (ob ? ob : orderBy);
    }
    const response = apiService<FilterResultSummary>('filter/summary' + query + '&check=true', 0);
    const dataResponse = apiService<FilterResultSummary>('filter' + pagedQuery(), -1);

    useEffect(() => {
        let sumIntervalId: null | NodeJS.Timeout = null;
        if (!searching && responseNotLoading(response) && response.result !== null && !response.result.finished) {
            setSearching(true);
            sumIntervalId = setInterval(
                () => {
                    response.fetchData('filter/summary' + query);
                    if (
                        !searchingData
                        && response.result !== null
                        && response.result.resultCount > 0
                        && (dataResponse.result == null
                            || dataResponse.result.resultCount < response.result.resultCount
                            || (responseNotLoading(dataResponse) && dataResponse.result.data.length < rowsPerPage))
                    ) {
                        setSearchingData(true);
                        dataResponse.fetchData(('filter') + pagedQuery());
                        setSearchingData(false);
                    }
                },
                1000);
            setSearching(false);
        } else if (
            responseNotLoading(response)
            && response.result !== null
            && response.result.finished
            && (dataResponse.result == null || dataResponse.result.resultCount < response.result.resultCount)) {
            dataResponse.fetchData('filter' + pagedQuery());
        }
        return () => {
            if (sumIntervalId) {
                clearInterval(sumIntervalId);
            }
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response, dataResponse, searching, searchingData, rowsPerPage, order, orderBy]);

    const handleLayoutChange = (b: boolean) => {
        setIsNewLayout(b);
        localStorage.setItem('newLayout', JSON.stringify(b));
    };

    const defaultContext = useMemo(
        () => ({
            response: response,
            dataResponse: dataResponse,
            getQuery: () => getQuery(),
            search: search,
            fetch: fetch,
            finish: finish,
            clear: clear,
            isNewLayout: isNewLayout,
            setIsNewLayout: handleLayoutChange,
            filters: {
                dateFrom: {val: dateFrom, set: setDateFrom, queryKey: 'from', type: FilterType.DATE},
                dateTo: {val: dateTo, set: setDateTo, queryKey: 'to', type: FilterType.DATE},
                insiderOwnership: {val: insiderOwnership, set: setInsiderOwnership, queryKey: 'own', type: FilterType.NUMERIC},
                priceRange: {val: priceRange, set: setPriceRange, queryKey: 'price', type: FilterType.NUMERIC},
                rvol: {val: rvol, set: setRvol, queryKey: 'rvol', type: FilterType.NUMERIC},
                pmRvol: {val: pmRvol, set: setPmRvol, queryKey: 'pmRvol', type: FilterType.NUMERIC},
                avgVol: {val: avgVol, set: setAvgVol, queryKey: 'avgVol', type: FilterType.NUMERIC},
                avgPmVol: {val: avgPmVol, set: setPmAvgVol, queryKey: 'avgPmVol', type: FilterType.NUMERIC},
                performance: {val: performance, set: setPerformance, queryKey: 'performance', type: FilterType.NUMERIC},
                performanceOpenHigh: {val: performanceOpenHigh, set: setPerformanceOpenHigh, queryKey: 'performanceOpenHigh', type: FilterType.NUMERIC},
                performanceOpenLow: {val: performanceOpenLow, set: setPerformanceOpenLow, queryKey: 'performanceOpenLow', type: FilterType.NUMERIC},
                performanceHighClose: {val: performanceHighClose, set: setPerformanceHighClose, queryKey: 'performanceHighClose', type: FilterType.NUMERIC},
                performanceLowClose: {val: performanceLowClose, set: setPerformanceLowClose, queryKey: 'performanceLowClose', type: FilterType.NUMERIC},
                performanceLowHigh: {val: performanceLowHigh, set: setPerformanceLowHigh, queryKey: 'performanceLowHigh', type: FilterType.NUMERIC},
                performanceHighLow: {val: performanceHighLow, set: setPerformanceHighLow, queryKey: 'performanceHighLow', type: FilterType.NUMERIC},
                performancePmHighHigh: {val: performancePmHighHigh, set: setPerformancePmHighHigh, queryKey: 'performancePmHighHigh', type: FilterType.NUMERIC},
                pmGap: {val: pmGap, set: setPmGap, queryKey: 'pmGap', type: FilterType.NUMERIC},
                pmHighGap: {val: pmHighGap, set: setPmHighGap, queryKey: 'pmHighGap', type: FilterType.NUMERIC},
                volumeYesterday: {val: volumeYesterday, set: setVolumeYesterday, queryKey: 'volumeYesterday', type: FilterType.NUMERIC},
                changePreviousDay: {val: changePreviousDay, set: setChangePreviousDay, queryKey: 'changePreviousDay', type: FilterType.NUMERIC},
                changeFiveDays: {val: changeFiveDays, set: setChangeFiveDays, queryKey: 'changeFiveDays', type: FilterType.NUMERIC},
                changeTenDays: {val: changeTenDays, set: setChangeTenDays, queryKey: 'changeTenDays', type: FilterType.NUMERIC},
                changeTwentyDays: {val: changeTwentyDays, set: setChangeTwentyDays, queryKey: 'changeTwentyDays', type: FilterType.NUMERIC},
                dailyRange: {val: dailyRange, set: setDailyRange, queryKey: 'dailyRange', type: FilterType.NUMERIC},
                dailyRangeAvg: {val: dailyRangeAvg, set: setDailyRangeAvg, queryKey: 'dailyRangeAvg', type: FilterType.NUMERIC},
                trueRange: {val: trueRange, set: setTrueRange, queryKey: 'trueRange', type: FilterType.NUMERIC},
                trueRangeAvg: {val: trueRangeAvg, set: setTrueRangeAvg, queryKey: 'trueRangeAvg', type: FilterType.NUMERIC},
                gap: {val: gap, set: setGap, queryKey: 'gap', type: FilterType.NUMERIC},
                marketCap: {val: marketCap, set: setMarketCap, queryKey: 'mc', type: FilterType.NUMERIC},
                shares: {val: shares, set: setShares, queryKey: 'shares', type: FilterType.NUMERIC},
                volume: {val: volume, set: setVolume, queryKey: 'vol', type: FilterType.NUMERIC},
                pmVolume: {val: pmVolume, set: setPmVolume, queryKey: 'pmVol', type: FilterType.NUMERIC},
                pmFade: {val: pmFade, set: setPmFade, queryKey: 'pmFade', type: FilterType.NUMERIC},
                pmBreak: {val: pmBreak, set: setPmBreak, queryKey: 'pmBreak', type: FilterType.NUMERIC},
                tickers: {val: tickers, set: setTickers, queryKey: 'tickers', type: FilterType.SELECT},
                sectors: {val: sectors, set: setSectors, queryKey: 'sectors', type: FilterType.SELECT},
                countries: {val: countries, set: setCountries, queryKey: 'countries', type: FilterType.SELECT},
                industries: {val: industries, set: setIndustries, queryKey: 'industries', type: FilterType.SELECT},
                dollarVolume: {val: dollarVolume, set: setDollarVolume, queryKey: 'dollarVolume', type: FilterType.NUMERIC},
                hodFrom: {val: hodFrom, set: setHodFrom, queryKey: 'hodFrom', type: FilterType.TIME},
                hodTo: {val: hodTo, set: setHodTo, queryKey: 'hodTo', type: FilterType.TIME},
                lodFrom: {val: lodFrom, set: setLodFrom, queryKey: 'lodFrom', type: FilterType.TIME},
                lodTo: {val: lodTo, set: setLodTo, queryKey: 'lodTo', type: FilterType.TIME},
                pmHighFrom: {val: pmHighFrom, set: setPmHighFrom, queryKey: 'pmHighFrom', type: FilterType.TIME},
                pmHighTo: {val: pmHighTo, set: setPmHighTo, queryKey: 'pmHighTo', type: FilterType.TIME},
                pmLowFrom: {val: pmLowFrom, set: setPmLowFrom, queryKey: 'pmLowFrom', type: FilterType.TIME},
                pmLowTo: {val: pmLowTo, set: setPmLowTo, queryKey: 'pmLowTo', type: FilterType.TIME},
                outputFrom: {val: outputFrom, set: setOutputFrom, queryKey: 'outFrom', type: FilterType.TIME},
                outputTo: {val: outputTo, set: setOutputTo, queryKey: 'outTo', type: FilterType.TIME},
            },
            pageAndRows: {
                page: page,
                rowsPerPage: rowsPerPage,
                set: (p: any, r?: any, invalidate?: boolean) => {
                    setPage(p);
                    if (r) {
                        setRowsPerPage(r);
                    }
                    if (invalidate) {
                        dataResponse.invalidateResult();
                    }
                    dataResponse.fetchData('filter' + pagedQuery(query, p, r));
                }
            },
            ordering: {
                order: order,
                orderBy: orderBy,
                set: (o: any, ob: any, invalidate?: boolean) => {
                    setOrder(o);
                    setOrderBy(ob);
                    if (invalidate) {
                        dataResponse.invalidateResult();
                    }
                    dataResponse.fetchData('filter' + pagedQuery(query, page, rowsPerPage, o, ob));
                }
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            response, dataResponse, getQuery, search, finish, clear,
            dateFrom, setDateFrom,
            dateTo, setDateTo,
            insiderOwnership, setInsiderOwnership,
            priceRange, setPriceRange,
            rvol, setRvol,
            pmRvol, setPmRvol,
            avgVol, setAvgVol,
            avgPmVol, setPmAvgVol,
            gap, setGap,
            marketCap, setMarketCap,
            shares, setShares,
            volume, setVolume,
            pmVolume, setPmVolume,
            pmFade, setPmFade,
            pmBreak, setPmBreak,
            tickers, setTickers,
            sectors, setSectors,
            industries, setIndustries,
            countries, setCountries,
            outputFrom, setOutputFrom,
            outputTo, setOutputTo,
            page, rowsPerPage,
            order, orderBy,
            query,
            hodFrom, setHodFrom,
            hodTo, setHodTo,
            lodFrom, setLodFrom,
            lodTo, setLodTo,
            pmHighFrom, setPmHighFrom,
            pmHighTo, setPmHighTo,
            pmLowFrom, setPmLowFrom,
            pmLowTo, setPmLowTo,
            performance, setPerformance,
            performanceOpenHigh, setPerformanceOpenHigh,
            performanceOpenLow, setPerformanceOpenLow,
            performanceHighClose, setPerformanceHighClose,
            performanceLowClose, setPerformanceLowClose,
            performanceLowHigh, setPerformanceLowHigh,
            performanceHighLow, setPerformanceHighLow,
            performancePmHighHigh, setPerformancePmHighHigh,
            pmGap, setPmGap,
            pmHighGap, setPmHighGap,
            volumeYesterday, setVolumeYesterday,
            changePreviousDay, setChangePreviousDay,
            changeFiveDays, setChangeFiveDays,
            changeTenDays, setChangeTenDays,
            changeTwentyDays, setChangeTwentyDays,
            dailyRange, setDailyRange,
            dailyRangeAvg, setDailyRangeAvg,
            trueRange, setTrueRange,
            trueRangeAvg, setTrueRangeAvg,
            dollarVolume, setDollarVolume,isNewLayout
        ]
    );

    return <FilterContext.Provider value={defaultContext}>{children}</FilterContext.Provider>;
}

export function useFilterContext(): FilterContextType {
    return useContext(FilterContext);
}

function emptyFilters() {
    return {
        dateFrom: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        dateTo: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        insiderOwnership: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        priceRange: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        rvol: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        pmRvol: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        avgVol: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        avgPmVol: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        performance: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        performanceOpenHigh: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        performanceOpenLow: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        performanceHighClose: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        performanceLowClose: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        performanceLowHigh: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        performanceHighLow: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        performancePmHighHigh: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        pmGap: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        pmHighGap: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        volumeYesterday: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        changePreviousDay: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        changeFiveDays: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        changeTenDays: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        changeTwentyDays: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        dailyRange: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        dailyRangeAvg: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        trueRange: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        trueRangeAvg: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        gap: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        marketCap: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        shares: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        volume: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        pmVolume: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        pmBreak: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        pmFade: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        tickers: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        sectors: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        industries: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        countries: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        outputFrom: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        outputTo: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        dollarVolume: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},

        hodFrom: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        hodTo: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        lodFrom: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        lodTo: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        pmHighFrom: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        pmHighTo: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        pmLowFrom: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
        pmLowTo: {val: null, set: noOp(), queryKey: '', type: FilterType.NUMERIC},
    };
}

function responseNotLoading(res: ApiResponse<any>): boolean {
    return !res.isLoading && !res.error && res.result;
}
